import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import HomeIcon from "@mui/icons-material/Home";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import sapLogo from "../images/Logo_S_Y.png";
import { useCookies } from "react-cookie";
import Modal from "@mui/material/Modal";
import axios from "axios";

// eslint-disable-next-line no-unused-vars
import styled from "styled-components/macro";
import BuildIcon from "@mui/icons-material/Build";
import { GiCarWheel } from "react-icons/gi";

export default function SignIn() {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const [permisos, setPermisos] = useState("");
  const [open, setOpen] = React.useState(false);
  const [database, setDatabase] = useState("");


console.log(database)



  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let navigate = useNavigate();

  function setCookieDM() {
    setCookie("Division", "DM");
    setCookie("Series", 156);
    navigate("/FormArticles");
  }

  function setCookieDH() {
    setCookie("Division", "DH");
    setCookie("Series", 156); //156 produccion, 148 test
    navigate("/FormArticles");
  }

  function setCookieDB() {
    setCookie("Division", "DB");
    setCookie("Series", 156);
    navigate("/FormArticles");
  }

  function setCookieDR() {
    setCookie("Division", "DR");
    navigate("/FormArticles");
  }

  function setCookieDN() {
    setCookie("Division", "DN");
    setCookie("Series", 162);
    navigate("/FormArticles");
  }

  function setCookieDS() {
    setCookie("Division", "DSG");
    setCookie("Series", 3);
    setCookie("database", database);
    navigate("/FormArticles");
  }


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 3,
    p: 4,
    borderRadius: 6,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const dataForm = {
      UserName: data.get("UserName"),
      Password: data.get("Password"),
      CompanyDB: data.get("CompanyDB"),
    };
    try {
      if(!database)
        return Swal.fire({
          position: "center",
          icon: "error",
          title: "Seleccione una empresa!",
          showConfirmButton: true,
          timer: 1500,
        });
      const resultLogin = await axios
        .post("http://masterapi.yuhmak.com/api/v1/Login", dataForm)
        .then((response) => response.data);

      if (resultLogin.status === "ok") {
        const queryOptions = {
          filter: `USER_CODE eq '${dataForm.UserName}'`,
        };
        await axios
          .post(
            "http://masterapi.yuhmak.com/api/v1/view/YUH_UNIDADNEGOCIOUSUARIO",
            queryOptions
          )
          .then((response) => {
            Swal.close();

            const Divisiones = response.data.details.map(
              (division) => division.name
            );

            if (Divisiones != null) {
              setPermisos(
                // eslint-disable-next-line array-callback-return
                Divisiones.map((element) => {
                  switch (element) {
                    case "Motos":
                      return "DM";
                    case "Hogar":
                      return "DH";
                    case "Bikes":
                      return "DB";
                    case "Repuestos":
                      return "DR";
                    case "Neumaticos":
                      return "DN";
                      case"Segumak":
                      return "DSG";
                    default:
                      return null;
                  }
                })
              );
            }

            handleOpen();
          });

        localStorage.setItem("userName", dataForm.UserName);
        localStorage.setItem("Articulos", "[]");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Usuario o contraseña incorrectos!",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Usuario o contraseña incorrectos!",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  function loading() {
    // let timerInterval;
    Swal.fire({
      title: "Conectando..",
      // timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: (resultLogin) => {
        if (resultLogin.statusCode === 200) {
          Swal.hideLoading();
        }
      },
    });
  }

  const handleChange = (event) => {
    setDatabase(event.target.value);
  };

  return (
    <>
      <img src={sapLogo} alt="logoSap" width="250px" />

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Alta de Articulos{" "}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="UserName"
              label="Usuario"
              name="UserName"
              autoComplete="UserName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="Password"
              label="Contraseña"
              type="Password"
              id="Password"
              autoComplete="current-Password"
            />
           <InputLabel id="CompanyDB">Empresa</InputLabel> 
            <Select
            labelId="CompanyDB"
            type="text"
              required
              fullWidth
              name="CompanyDB"
              label="Base de Datos"
              id="CompanyDB"
              autoComplete="CompanyDB"
              onChange={handleChange}
              placeholder="Seleccione la empresa"
              displayEmpty
              renderValue={database !== "" ? undefined : () => "Seleccione la empresa..."}
              value={database}
            >
              <MenuItem value={"SEGUMAK_PRD"}>Segumak</MenuItem>
              <MenuItem value={"YUHMAKSA_PRD"}>Yuhmak</MenuItem>
            </Select>

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recuerdame"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              css={"text-align:center !important;"}
              variant="h4"
              component="h4"
            >
              Seleccionar Division
            </Typography>

            {permisos.includes("DM") ? (
              <Tooltip title="MOTOS">
                <Button
                  className="btn-division"
                  sx={{ backgroundColor: "rgb(18, 204, 39)", marginLeft: 7 }}
                  onClick={setCookieDM}
                >
                  <SportsMotorsportsIcon
                    sx={{ fontSize: 40, color: "white" }}
                  ></SportsMotorsportsIcon>
                </Button>
              </Tooltip>
            ) : (
              null
            )}
            {permisos.includes("DH") ? (
              <Tooltip title="HOGAR">
                <Button
                  className="btn-division"
                  css={
                    "margin-left:10px !important; background-color: #13c0db ;"
                  }
                  onClick={setCookieDH}
                >
                  <HomeIcon sx={{ fontSize: 40, color: "white" }}></HomeIcon>
                </Button>
              </Tooltip>
            ) : (
              null
            )}
            {permisos.includes("DB") ? (
              <Tooltip title="BIKES">
                <Button
                  className="btn-division"
                  sx={{
                    backgroundColor: "#e77919",
                    marginLeft: "10px !important",
                  }}
                  onClick={setCookieDB}
                >
                  <PedalBikeIcon
                    sx={{ fontSize: 40, color: "white" }}
                  ></PedalBikeIcon>
                </Button>
              </Tooltip>
            ) : (
              null
            )}
            {permisos.includes("DR") ? (
              <Tooltip title="REPUESTOS">
                <Button
                  className="btn-division"
                  sx={{
                    backgroundColor: "#5e30a8",
                    marginLeft: "10px !important",
                  }}
                  onClick={setCookieDR}
                >
                  <BuildIcon sx={{ fontSize: 40, color: "white" }}></BuildIcon>
                </Button>
              </Tooltip>
            ) : (
              null
            )}
            {permisos.includes("DN") ? (
              <Tooltip title="NEUMATICOS">
                <Button
                  className="btn-division"
                  sx={{
                    backgroundColor: "#ff0010",
                    marginLeft: "10px !important",
                  }}
                  onClick={setCookieDN}
                >
                  <GiCarWheel style={{ fontSize: 40, color: "white" }} />
                </Button>
              </Tooltip>
            ) : (
              null
            )}
            {permisos.includes("DSG") ? (
              <Tooltip title="SEGUMAK">
                <Button
                  className="btn-division"
                  sx={{
                    backgroundColor: "#4c2882",
                    marginLeft: "10px !important",
                  }}
                  onClick={setCookieDS}
                >
                  <AddBusinessIcon style={{ fontSize: 40, color: "white" }} />
                </Button>
              </Tooltip>
            ) : (
              null
            )}
          </Box>
        </Modal>
      </Container>
    </>
  );
}
