/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import { useEffect, useState, useContext } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { GlobalContext } from "../context/GlobalContext.js";
import TableArticles from "../components/TableArticles";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Swal from "sweetalert2";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useCookies } from "react-cookie";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import ejemplo from "../images/Ejemplo_serie.jpg";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { Stack, Typography } from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

const theme = createTheme();

export const FormArticles = () => {
  const { addArticle } = useContext(GlobalContext);

  const [cookies, removeCookie] = useCookies();

  const [selectRubros, setSelectRubros] = useState([]);
  const [selectSubRubros, setSelectSubRubros] = useState([]);
  const [selectMarcas, setSelectMarcas] = useState([]);
  const [selectModelos, setSelectModelos] = useState([]);
  const [newModelName, setNewModelName] = useState("");
  const [showModelModal, setShowModelModal] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [ultimoCodigo, setUltimoCodigo] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [itemsModal, setItemsModal] = useState(false);
  const [selectedItemsDetails, setSelectedItemDetails] = useState([]);
  const [selectedItemGroupCode, setSelectedItemGroupCode] = useState("");
  const [selectedManageSerialNumbers, setSelectedManageSerialNumbers] = useState("");



  const openModelModal = () => {
    setShowModelModal(true);
  };

  const closeModelModal = () => {
    setShowModelModal(false);
  };

  const openItemsModal = () => {
    setItemsModal(true);
  };

  const closeItemsModal = () => {
    setItemsModal(false);
  };

  useEffect(() => {
    if (cookies.Division) {
      getRubros();
      getMarcas();
      getSubrubros()
      getModelos();
    } else {
      window.location.assign("/");
    }
  }, []);

  let getMarcas = async () => {
    try {
      getMarcas = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/MARCAS")
        .then((response) => setSelectMarcas(response.data.details));
      Swal.close();
    } catch (error) {
      if (error.message.length > 0) window.location.assign("/");
    }
  };

  let getSubrubros = async (U_Rubro) => {
    const queryOptions = { filter: U_Rubro };
    try {
      getRubros = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/SUBRUBROS", queryOptions)
        .then((response) => {
          setSelectSubRubros(response.data.details);
        });
    } catch (error) {}
  };

  let getRubros = async () => {
    const queryOptions = {
      filter: `U_Division eq '${cookies?.Division}'`,
    };
    try {
      getRubros = await axios
        .post("http://masterapi.yuhmak.com/api/v1/all/RUBROS", queryOptions)
        .then((response) => setSelectRubros(response.data.details));
      Swal.close();
    } catch (error) {}
  };

  const getItems = async (inputValue) => {

    Swal.fire({
      title: 'buscando items...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        document.querySelector('.swal2-container').style.zIndex = 9999;
      },
    });
    const queryOptions = {
      filter: `contains(ItemName, '${inputValue}') or contains(ItemCode, '${inputValue}')`,
    };
    try {
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/Items",
        queryOptions
      );

      const items = response.data.details.map((item) => ({
        value: item.ItemCode,
        label: `${item.ItemName} - ${item.ItemCode}`,
        details: item,
      }));
      console.log("soy items", items);
      setSelectItems(items);
      Swal.close();
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al buscar los elementos.',
      });
    }
    console.log(selectItems);
  };

  const updateItemData = async (
    selectedItemDetails,
    selectedItemGroupCode,
    selectedManageSerialNumbers
  ) => {
    try {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          document.querySelector('.swal2-container').style.zIndex = 9999;
        },
      });
      // Modificar el campo ItemCode según tus necesidades
      const newItemCode = `BU${selectedItemDetails.ItemCode.substring(2)}`;

      // Realizar la actualización del campo ItemCode
      const updatedItem = {
        ItemCode: newItemCode,
        ItemName: selectedItemDetails.ItemName,
        ItemType: "itItems",
        U_Division: selectedItemDetails.U_Division,
        U_Rubro: selectedItemDetails.U_Rubro,
        U_Subrubros: selectedItemDetails.U_Subrubros,
        ManageSerialNumbers: selectedManageSerialNumbers.value,
        ItemsGroupCode: selectedItemGroupCode.value,
      };

      await axios.post(
        `http://masterapi.yuhmak.com/api/v1/postData/Items`,
        updatedItem
      );
      Swal.fire({
        icon: 'success',
        title: 'Subido con éxito',
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = 99999;
        }
      });
      setSelectedItemDetails([]);
      setSelectedItemGroupCode("");
    setSelectedManageSerialNumbers("");

      closeItemsModal();
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error al subir',
        text: 'Ocurrió un error al subir la información. Inténtalo de nuevo.',
        didOpen: () => {
          document.querySelector('.swal2-container').style.zIndex = 99999;
        }
      });
      setSelectedItemDetails([]);
      setSelectedItemGroupCode("");
      setSelectedManageSerialNumbers("");
    }
  };

  const loadOptions = async (inputValue, callback) => {
    const queryOptions = {
      filter: `CardType eq 'cSupplier' and startswith(CardName, '${inputValue.toUpperCase()}' )`,
    };
    if (inputValue.length > 2) {
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/BusinessPartners",
        queryOptions
      );

      const json = await response.data.details;

      callback(
        json.map(({ CardCode, CardName }) => ({
          label: CardName + " - " + CardCode,
          value: CardCode,
        }))
      );
    }
  };

  const loadOptions2 = async (inputValue, callback) => {
    const filterType = cookies.Division === "DSG" 
      ? `contains(GroupName, '${inputValue}')`
      : `startswith(GroupName, '${inputValue.toUpperCase()}')`;
  
    const queryOptions = {
      filter: filterType,
      select: "Number, GroupName",
    };
  
    // Solo realiza la solicitud si el input tiene más de 2 caracteres
    if (inputValue.length > 2) {
      try {
        const response = await axios.post(
          "http://masterapi.yuhmak.com/api/v1/all/ItemGroups",
          queryOptions
        );
  
        const json = await response.data.details;
  
        callback(
          json.map(({ Number, GroupName }) => ({
            label: GroupName + "-" + Number,
            value: Number,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  
  let getModelos = async () => {
    try {
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/MODELOS"
      );
      setSelectModelos(response.data.details);
      Swal.close();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loading();
  }, []);

  const createNewModel = async (values) => {
    try {
      setNewLoading(true);

      if (values.U_Modelo.trim() === "") {
        console.error("El nombre del modelo es obligatorio.");
        return;
      }

      // Obtener el último código de modelo
      const queryOptions = {
        orderby: "Code desc",
        top: 1,
      };
      const response = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/all/MODELOS",
        queryOptions
      );

      // Calcular el nuevo código de modelo
      const lastModel = response.data.details[0];
      const lastCode = lastModel ? parseInt(lastModel.Code) : 0;
      const newCode = (lastCode + 1).toString();
      const desiredLength = 5;
      const paddedNewCode = newCode.padStart(desiredLength, "0");

      // Crear el nuevo modelo
      const newModel = {
        Code: paddedNewCode,
        Name: values.U_Modelo,
        U_Marca: values.U_Marca.value,
      };

      // Hacer la solicitud POST para crear el nuevo modelo
      const createModelResponse = await axios.post(
        "http://masterapi.yuhmak.com/api/v1/postData/MODELOS",
        newModel
      );

      // Manejar la creación exitosa del nuevo modelo
      if (createModelResponse.data.status === "ok") {
        setNewModelName(newModel.Name);
        setUltimoCodigo(paddedNewCode);

        Swal.fire({
          title: "Nuevo Modelo Creado",
          icon: "success",
        });
        console.log("Nuevo modelo creado:", createModelResponse.data);
        console.log("Soy newModelName", newModelName);
      }

      // Cerrar el modal y restablecer los campos del formulario
      closeModelModal();
      //  actualizar la lista de modelos para reflejar el nuevo modelo
      setSelectModelos((prevModels) => [...prevModels, newModel]);

      // Ocultar el mensaje de carga después de completar la operación
      setNewLoading(false);
    } catch (error) {
      // Manejar errores de la solicitud
      console.error("Error al crear el nuevo modelo:", error.message);
      setNewLoading(false);
    }
  };

  const selectRubrosMap = selectRubros?.map(({ Code, Name }) => ({
    value: Code,
    label: Name + " - " + Code,
  }));

  const selectSubRubrosMap = selectSubRubros?.map(({ Code, Name }) => ({
    value: Code,
    label: Name + " - " + Code,
  }));

  const selectMarcasMap = selectMarcas?.map(({ Code, Name }) => ({
    value: Code,
    label: Name + " - " + Code,
  }));



  const optionsSeries = [
    { value: "tNO", label: "No" },
    { value: "tYES", label: "Si" },
  ];

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      placement="right-start"
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function loading() {
    // let timerInterval;
    Swal.fire({
      title: "Obteniendo Datos..",
      // timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: (selectMarcasMap) => {
        if (selectMarcasMap.length > 0) {
          Swal.hideLoading();
        }
      },
    });
  }

  function loading2() {
    // let timerInterval;
    Swal.fire({
      title: "Obteniendo SubRubros..",
      timer: 3500,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: (selectSubRubrosMap) => {
        if (selectSubRubrosMap.length > 0) {
          Swal.hideLoading();
        }
      },
    });
  }

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "repeat(1, 1fr)",
            lg: "grid",
            gridTemplateColumns: "repeat(2, 2fr)",
            xl: "grid",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <Container>
            <Formik
              initialValues={{
                ItemName: "",
                ItemsGroupCode: "",
                BarCode: "",
                VatLiable: "tYES",
                PurchaseItem: "tYES",
                SalesItem: "tYES",
                InventoryItem: "tYES",
                Mainsupplier: "",
                SupplierCatalogNo: "",
                ManageSerialNumbers: "tNO", //NÚMERODESERIE - DESPLEGABLE SI-NO
                SalesUnit: "UNIDAD",
                PurchaseUnit: "UNIDAD",
                WTLiable: "tYES",
                SWW: " ", //MISMO VALOR SupplierCatalogNo
                IndirectTax: "tYES",
                ArTaxCode: "", //Opciones: IVA_21 // IVA_10.5
                ApTaxCode: "", //MISMO VALOR ArTaxCode
                InventoryUOM: "UNIDAD",
                Series: cookies?.Series,

                ItemPrices: [
                  {
                    PriceList: 1,

                    Price: "",

                    Currency: cookies.Division === "DSG"? "USD":"ARS",

                    BasePriceList:cookies.Division === "DSG"? 1 : 13,

                    Factor: cookies.Division === "DSG"? 1 : "",
                  },
                  {
                    PriceList: cookies.Division === "DSG"? 2 : 13,
                    Price: "",

                    Currency: "ARS",

                    BasePriceList: cookies.Division === "DSG"? 1 : 13,

                    Factor: 1.0,
                  },
                ],
                UoMGroupEntry: 1,
                InventoryUoMEntry: 1,
                DefaultSalesUoMEntry: 1,
                DefaultPurchasingUoMEntry: 1,
                U_Division: parseInt(cookies?.Series),
                U_Rubro: "",
                U_Subrubros: "",
                U_Marca: "",
                U_Modelo: "",
                U_ano: "",
                U_importe: "",
                ItemCode: "",
                U_Oferta:"Si",
                U_VencOferta:"",
                U_PorcOferta:"",
                U_Categoria: "",
              }}
              validate={(valores) => {
                let errores = {};
                if (!valores.ItemName) {
                } else if (
                  !/^[A-Za-z0-9\/\-\(\)\\\sáéíóúÁÉÍÓÚÑñ\.\+]{3,100}$/.test(
                    valores.ItemName
                  )
                ) {
                  errores.ItemName = "Caracteres invalidos";
                }

                return errores;
              }}
              onSubmit={(valores, { resetForm }) => {
                addArticle(valores);
                getItems(valores);
                resetForm();
                document.getElementById("artCompra").value = "tYES";
                document.getElementById("artVenta").value = "tYES";
                document.getElementById("artInventariable").value = "tYES";
              }}
            >
              {({
                values,
                errors,
                setFieldValue,
                handleChange,
                handleBlur,
              }) => (
                <Form className="form-articulo" noValidate>
                  <p className="title-create">Crear Articulo</p>
                  <br />
                  <Tooltip title="BIENES DE USO">
                    <Button
                      className="btn-division"
                      sx={{
                        backgroundColor: "rgb(18, 204, 39)",
                        marginLeft: 0,
                        marginTop: 10,
                      }}
                      onClick={openItemsModal}
                    >
                      <AddBusinessIcon sx={{ fontSize: 40, color: "white" }} />
                    </Button>
                  </Tooltip>

                  <Modal open={itemsModal} onClose={closeItemsModal}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 600,
                        height: 600,
                        bgcolor: "background.paper",
                        border: "2px",
                        borderRadius: "10px",
                        boxShadow: 24,
                        p: 5,
                      }}
                    >
                      <Stack spacing={2.1}>
                        <Typography variant="h5">
                          Agregar Bienes de uso
                        </Typography>
                        <TextField
                          margin="normal"
                          fullWidth
                          type="text"
                          value={values.ItemName}
                          onChange={(e) =>
                            setFieldValue("ItemName", e.target.value)
                          }
                          onBlur={handleBlur}
                          autoFocus
                        />
                        <Button
                          variant="outlined"
                          onClick={() => getItems(values.ItemName)}
                        >
                          Buscar
                        </Button>
                       
                        {selectItems.length > 0 && (
                          <Select
                            options={selectItems}
                            placeholder="Selecciona un item"
                            id={"ItemsName"}
                            type={"text"}
                            value={selectItems.ItemName}
                            onChange={(option) => {
                              setFieldValue("ItemName", option.label);
                              setSelectedItemDetails(option.details);
                              console.log(
                                "Detalles del elemento seleccionado:",
                                selectedItemsDetails
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        )}
                        <AsyncSelect
                          value={values.ItemsGroupCode}
                          loadOptions={loadOptions2}
                          id={"ItemsGroupCode"}
                          type={"text"}
                          onBlur={handleBlur}
                          onChange={(option) => {
                            setFieldValue(
                              "ItemsGroupCode",
                              selectedItemGroupCode.label,console.log("soy setfieldvalue1", selectedItemGroupCode)
                            ); 
                            setSelectedItemGroupCode(option);
                          }}
                          placeholder="Grupo de Articulos"
                        />

                        <Select
                          options={optionsSeries}
                          placeholder="Número de serie"
                          id={"ManageSerialNumbers"}
                          type={"text"}
                          value={values.ManageSerialNumbers}
                          onChange={(option) => {
                            setFieldValue(
                              "ManageSerialNumbers",
                              selectedManageSerialNumbers.label,console.log("soy setfieldvalue2",selectedManageSerialNumbers) 
                            ); // Asigna el label en lugar del valor
                            setSelectedManageSerialNumbers(option);
                          }}
                          onBlur={handleBlur}
                        />

                        <Button
                          variant="contained"
                          onClick={() =>
                            updateItemData(
                              selectedItemsDetails,
                              selectedItemGroupCode,
                              selectedManageSerialNumbers
                            )
                          }
                        >
                          Agregar
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>

                  <TextField
                    margin="normal"
                    fullWidth
                    id="ItemName"
                    type="text"
                    value={values.ItemName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ItemName"
                    label="Descripción del artículo"
                    autoFocus
                    error={
                      !/^[A-Za-z0-9\/\-\(\)\\\sáéíóúÁÉÍÓÚÑñ\.\+]{0,100}$/.test(
                        values.ItemName
                      )
                    }
                    helperText={
                      /^[A-Za-z0-9\/\-\(\)\\\sáéíóúÁÉÍÓÚÑñ\.\+]{3,100}$/.test(
                        values.ItemName
                      ) || values.ItemName === ""
                        ? ""
                        : "Caracteres Invalidos"
                    }
                  />

                  <AsyncSelect
                    value={values.Mainsupplier}
                    loadOptions={loadOptions}
                    id={"Mainsupplier"}
                    type={"text"}
                    onBlur={handleBlur}
                    error
                    onChange={(option) => setFieldValue("Mainsupplier", option)}
                    placeholder="Proveedor"
                  />
                  <div className="textProv">
                    Escriba 3 caracteres para empezar a buscar..
                  </div>
                  <br />

                  <Select
                    options={selectMarcasMap}
                    placeholder="Marca"
                    id={"U_Marca"}
                    type={"text"}
                    value={values.U_Marca}
                    onChange={(option) => setFieldValue("U_Marca", option)}
                    onBlur={handleBlur}
                  />
                  <br />

                  <Select
                    options={selectRubrosMap}
                    placeholder="Rubro"
                    id={"U_Rubro"}
                    type={"text"}
                    value={values.U_Rubro}
                    onChange={(option) => {
                      values.U_Subrubros = {
                        value: "",
                        label: "--Seleccionar Subrubro--",
                      };
                      setFieldValue("U_Rubro", option);
                      getSubrubros(`U_Rubro eq '${option.value}'`);
                      loading2();
                    }}
                    onBlur={handleBlur}
                  />

                  <br />
                  <Select
                    options={selectSubRubrosMap}
                    search
                    placeholder="Subrubros"
                    id={"U_Subrubros"}
                    type={"text"}
                    value={values.U_Subrubros}
                    onChange={(option) => setFieldValue("U_Subrubros", option)}
                    onBlur={handleBlur}
                  />

                  <br />

                  <AsyncSelect
                    value={values.ItemsGroupCode}
                    loadOptions={loadOptions2}
                    id={"ItemsGroupCode"}
                    type={"text"}
                    onBlur={handleBlur}
                    onChange={(option) =>
                      setFieldValue("ItemsGroupCode", option)
                    }
                    placeholder="Grupo de Articulos"
                  />

                  <div className="textProv">
                    Mismo que Subrubro..(3 caracteres para empezar a buscar)
                  </div>

                  {cookies.Division === "DB" ? (
                    <HtmlTooltip
                      title={
                        <>
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="240"
                              image={ejemplo}
                              alt="ejemplo_cod"
                            />
                          </Card>
                        </>
                      }
                    >
                      <Button>
                        <Select
                          options={optionsSeries}
                          placeholder="Número de serie"
                          id={"ManageSerialNumbers"}
                          type={"text"}
                          value={values.ManageSerialNumbers}
                          onChange={(option) =>
                            setFieldValue("ManageSerialNumbers", option)
                          }
                          onBlur={handleBlur}
                        />
                      </Button>
                    </HtmlTooltip>
                  ) : (
                    ""
                  )}

                  {cookies.Division === "DM" &&
                  values.U_Rubro.value === "000016" ? (
                    <div>
                      <TextField
                        label="Año"
                        value={values.U_ano}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        fullWidth
                        id="U_ano"
                        type="number"
                      />
                      <Button variant="contained" onClick={openModelModal}>
                        Crear Nuevo Modelo
                      </Button>
                      {!values.U_Modelo && (
                        <p style={{ color: "red" }}>
                          Por favor, complete el campo crear nuevo Modelo
                        </p>
                      )}
                    </div>
                  ) : null}

                  <Modal open={showModelModal} onClose={closeModelModal}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "100%",
                        bgcolor: "background.paper",
                        border: "2px",
                        borderRadius: "10px",
                        boxShadow: 24,
                        p: 5,
                      }}
                    >
                      <Stack spacing={1.9}>
                        <Typography variant="h5" fontWeight="semi-bold">
                          CREA UN NUEVO MODELO
                        </Typography>
                       
                        <TextField
                          id={"U_Modelo"}
                          label="Nombre del Modelo"
                          value={values.U_Modelo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          required
                          helperText="crear un nuevo modelo es obligatorio"
                          sx={{ marginBottom: "20px", width: 400 }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => createNewModel(values)}
                          sx={{
                            right: -10,
                            top: 15,
                            fontSize: 17,
                          }}
                        >
                          Crear Modelo
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>

                  <div className="customSelect__container">
                    <div className="customSelect">
                      <label htmlFor="artCompra">¿ Art. Compra ?</label>
                      <select
                        id="artCompra"
                        onChange={(option) =>
                          setFieldValue("PurchaseItem", option.target.value)
                        }
                      >
                        <option value="tYES">Sí</option>
                        <option value="tNO">No</option>
                      </select>
                    </div>

                    <div className="customSelect">
                      <label htmlFor="artVenta">¿ Art. Venta ?</label>
                      <select
                        id="artVenta"
                        onChange={(option) =>
                          setFieldValue("SalesItem", option.target.value)
                        }
                      >
                        <option value="tYES">Sí</option>
                        <option value="tNO">No</option>
                      </select>
                    </div>

                    <div className="customSelect">
                      <label htmlFor="artInventariable">
                        ¿ Inventariable ?
                      </label>
                      <select
                        id="artInventariable"
                        onChange={(option) =>
                          setFieldValue("InventoryItem", option.target.value)
                        }
                      >
                        <option value="tYES">Sí</option>
                        <option value="tNO">No</option>
                      </select>
                    </div>
                    <div className="customSelect">
                      <label htmlFor="artInventariable">
                         Tamaño 
                      </label>
                      <select
                        id="U_Categoria"
                        onChange={(option) =>
                          setFieldValue("U_Categoria", option.target.value)
                        }
                      ><option value="">seleccione</option>
                        <option value="BIG">BIG</option>
                        <option value="MED">MED</option>
                        <option value="PEQ">PEQ</option>
                      </select>
                    </div>
                  </div>
                        {
                          cookies.Division === "DSG" &&(
                            <div className="customSelect__container">
                               <div className="customSelect">    
                                <label htmlFor="U_Oferta">Oferta</label>
                                <select name="U_Oferta" id="U_Oferta" value={values.U_Oferta} onChange={(option) =>
                          setFieldValue("U_Oferta", option.target.value)
                        }>
                                <option value="Si">Sí</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <div className="customSelect">
                              <label htmlFor="U_PorcOferta">% oferta</label>
                              <TextField  id="U_PorcOferta" 
                              type="text" 
                              value={values.U_PorcOferta} 
                              onChange={(option) =>
                                setFieldValue("U_PorcOferta", option.target.value)}>
                              </TextField>
                            </div>
                            <div className="customSelect">
                              <label htmlFor="U_VencOferta">Fecha venc Oferta</label>
                              <TextField  id="U_VencOferta"
                               type="date" 
                              value={values.U_VencOferta} 
                              onChange={(option) =>
                                setFieldValue("U_VencOferta", option.target.value)}>
                              </TextField>
                            </div>
                          </div>
                          )


                        }


                  <TextField
                    id="ItemPrices[1].Price"
                    value={values.ItemPrices[1].Price}
                    error={
                      !/^[\d]{0,8}[\.]{0,1}[\d]{0,2}$/.test(
                        values.ItemPrices[1].Price
                      )
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ItemPrices[1].Price"
                    label="Costo"
                    helperText="Solo números y un punto."
                    type="number"
                  />

<TextField
  id="ItemPrices[0].Factor"
  type="number"
  value={
    cookies.Division === "DSG"
      ? values.ItemPrices[1].Price
      : values.ItemPrices[0].Factor
  }
  onChange={(e) => {
    // Utilizamos setFieldValue para manejar cambios manualmente si es necesario
    setFieldValue(
     cookies.Division === "DSG"
        ? "ItemPrices[1].Price"
        : "ItemPrices[0].Factor",
      e.target.value
    );
  }}
  onBlur={handleBlur} // Manejamos el blur con Formik
  label={
    values.U_Rubro.value === "000016"
      ? "Precio venta"
      : "Factor"
  }
  name={cookies.Division === "DSG"? "ItemPrices[1].Price" : "ItemPrices[0].Factor"}  // Nombre dinámico
  error={
    !/^[\d]{0,8}[\.]{0,1}[\d]{0,2}$/.test(
      cookies.Division === "DSG"
        ? values.ItemPrices[1].Price
        : values.ItemPrices[0].Factor
    )
  }
  helperText="Solo números y un punto."
/>

                  <TextField
                    margin="normal"
                    fullWidth
                    type="text"
                    id="SupplierCatalogNo"
                    name="SupplierCatalogNo"
                    label="Cod.Fabricante"
                    value={values.SupplierCatalogNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    inputProps={{
                      maxLength: 16,
                    }}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    id="BarCode"
                    name="BarCode"
                    label="Cod.Barras"
                    value={values.BarCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 13);
                    }}
                  />

                  <br />

                  <Field type="radio" name="ApTaxCode" value="IVA_21" />
                  <b className="input_iva">IVA_21</b>

                  <Field type="radio" name="ApTaxCode" value="IVA_10.5" />
                  <b>IVA_10.5</b>

                  {cookies.Division==="DSG" &&(

                    <div>
                      <TextField
                        label="Código de Articulo"
                        value={values.ItemCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        fullWidth
                        id="ItemCode"
                        type="text"
                        inputProps={{
                          min: 0,
                        }}
                      />
                    </div>
                  )

                  }

                  {values.ApTaxCode &&
                  values.U_Rubro &&
                  values.ItemName &&
                  values.ManageSerialNumbers &&
                  values.ItemsGroupCode &&
                  values.U_Subrubros.value !== "" &&
                  errors.ItemName !== "Caracteres invalidos" ? (
                    <Button
                      onClick={() =>
                        (values.ArTaxCode = values.ApTaxCode) &&
                        (values.SWW = values.SupplierCatalogNo)
                      }
                      type="submit"
                      fullWidth
                      disabled={
                        Object.keys(errors).length > 0 ||
                        (values.U_Rubro.value === "000016" &&
                          values.U_Modelo !== newModelName)
                      }
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      onClick={() => (values.ArTaxCode = values.ApTaxCode)}
                      type="submit"
                      fullWidth
                      disabled
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Agregar
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </Container>
          <Container className="container-table">
            <TableArticles />
          </Container>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default FormArticles;
