import { Routes, Route, HashRouter } from "react-router-dom";
import Login from "./pages/Login.jsx";
import FormArticles from "./pages/FormArticles.jsx";
import TableArticles from "./components/TableArticles";
import Navbar from "./components/Navbar.jsx";
import { CookiesProvider } from "react-cookie";
import { ContextProvider } from "./context/GlobalContext";
import React from "react";

function App() {
  return (
    <>
      <CookiesProvider>
        <ContextProvider>
          <HashRouter basename="/">
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/Table" element={<TableArticles />} />
              <Route path="/FormArticles" element={<FormArticles />} />
              <Route path="/FormArticles/edit/:id" element={<FormArticles />} />
            </Routes>
          </HashRouter>
        </ContextProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
